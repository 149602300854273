<template>
  <v-col class="examples">
    <span
      class="examples__close"
      @click="closeExamples()">
      <v-icon>close</v-icon>
    </span>

    <template v-if="showCodeExampleFor('AWSCloudWatchLogs')">
      <p class="examples__message">
        {{ $t('syntaxAWSExampleMessageBefore') }}
        <a
          :href="$docLinks.project.logs.syntaxExamples.aws"
          class="cy-link examples__message__link"
          rel="noopener noreferrer"
          target="_blank">
          {{ $t('syntaxAWSExampleMessageLink') }}
        </a>
        {{ $t('syntaxAWSExampleMessageAfter') }}:
      </p>
      <p
        v-for="(syntaxExample, index) in $static.logProviderExampleList.AWSCloudWatchLogs"
        :key="index"
        class="examples__code">
        <strong>{{ syntaxExample.code }}</strong>
        <em class="ml-2 examples__code-comment">{{ syntaxExample.comment }}</em>
      </p>
    </template>

    <template v-if="showCodeExampleFor('ElasticsearchLogs')">
      <p class="examples__message">
        <span>{{ $t('syntaxElasticsearchExampleMessageBefore') }} </span>
        <a
          :href="$docLinks.project.logs.syntaxExamples.elasticsearch"
          class="cy-link examples__message__link"
          rel="noopener noreferrer"
          target="_blank">{{ $t('syntaxElasticsearchExampleMessageLink') }}</a>
        <!-- TODO: FE#886 Add elastic search examples -->
        <span v-if="false">{{ $t('syntaxElasticsearchExampleMessageAfter') }}</span>
      </p>
      <!-- TODO: FE#886 Add elastic search examples -->
      <template v-if="false">
        <p
          v-for="(syntaxExample, index) in $static.logProviderExampleList.ElasticsearchLogs"
          :key="index"
          class="examples__code">
          <strong>{{ syntaxExample.code }}</strong>
          <em class="ml-6 examples__code-comment">{{ syntaxExample.comment }}</em>
        </p>
      </template>
    </template>
  </v-col>
</template>

<script>

export default {
  name: 'CyCodeSyntaxExamples',
  props: {
    logProvider: {
      type: String,
      required: true,
      default: '',
    },
  },
  computed: {
    $static () {
      return {
        logProviderExampleList: {
          AWSCloudWatchLogs: [
            {
              code: '{$.message = "*error*"}',
              comment: this.$t('syntaxAWSExampleContains'),
            },
            {
              code: '{$.hostname = "ip-10-3-5-165"}',
              comment: this.$t('syntaxAWSExampleValue'),
            },
            {
              code: '{$.message = "*error*" && $.hostname = "ip-10-3-5-165"}',
              comment: this.$t('syntaxAWSExampleConditions'),
            },
          ],
          // TODO: FE#886 amend/update ElasticsearchLogs to display the correct code for it
          ElasticsearchLogs: [
            {
              code: '{$.message = "*error*"}',
              comment: this.$t('syntaxAWSExampleContains'),
            },
            {
              code: '{$.hostname = "ip-10-3-5-165"}',
              comment: this.$t('syntaxAWSExampleValue'),
            },
            {
              code: '{$.message = "*error*" && $.hostname = "ip-10-3-5-165"}',
              comment: this.$t('syntaxAWSExampleConditions'),
            },
          ],
        },
      }
    },
  },
  methods: {
    closeExamples () {
      this.$emit('close-examples')
    },
    showCodeExampleFor (engine) {
      return engine === this.logProvider
    },
  },
  i18n: {
    messages: {
      en: {
        syntaxAWSExampleConditions: 'aggregate conditions',
        syntaxAWSExampleContains: 'containing substring',
        syntaxAWSExampleMessageAfter: ', or take a look at these examples',
        syntaxAWSExampleMessageBefore: 'This uses AWS Cloudwatch logs syntax. Check the',
        syntaxAWSExampleMessageLink: 'documentation',
        syntaxAWSExampleValue: 'exact value',
        syntaxElasticsearchExampleMessageAfter: ', or take a look at these examples',
        syntaxElasticsearchExampleMessageBefore: 'This uses Elasticsearch logs syntax. Check the',
        syntaxElasticsearchExampleMessageLink: 'documentation',
      },
      es: {
        syntaxAWSExampleConditions: 'condiciones agregadas',
        syntaxAWSExampleContains: 'contiene un substring',
        syntaxAWSExampleMessageAfter: ', o inspirarse de estos ejemplos',
        syntaxAWSExampleMessageBefore: 'Este campo utiliza la sintaxis de AWS Cloudwatch. Puede encontrar la',
        syntaxAWSExampleMessageLink: 'documentación aquí',
        syntaxAWSExampleValue: 'valor exacto ',
        syntaxElasticsearchExampleMessageAfter: ', o inspirarse de estos ejemplos',
        syntaxElasticsearchExampleMessageBefore: 'Este campo utiliza la sintaxis de Elasticsearch. Puede encontrar la',
        syntaxElasticsearchExampleMessageLink: 'documentación aquí',
      },
      fr: {
        syntaxAWSExampleConditions: 'conditions aggregées',
        syntaxAWSExampleContains: `contiens un 'substring'`,
        syntaxAWSExampleMessageAfter: ', ou vous inspirer de ces exemples',
        syntaxAWSExampleMessageBefore: 'Ce champ utilise la syntaxe de AWS Cloudwatch. Vous pouvez trouver la',
        syntaxAWSExampleMessageLink: 'documentation ici',
        syntaxAWSExampleValue: 'valeur exacte',
        syntaxElasticsearchExampleMessageAfter: ', ou vous inspirer de ces exemples',
        syntaxElasticsearchExampleMessageBefore: 'Ce champ utilise la syntaxe de Elasticsearch. Vous pouvez trouver la',
        syntaxElasticsearchExampleMessageLink: 'documentation ici',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.examples {
  position: relative;
  width: 100%;
  padding: 0.5em 3em 0.5em 1.25em;
  border-top: 3px solid get-color("secondary");
  background: get-color("grey", "light-3");
  font-size: 1rem;

  p {
    margin-bottom: 0.5em;
    text-align: left;
  }
}

.examples__message__link {
  margin-right: -2px;

  &:hover {
    color: get-color("accent");
  }
}

.examples__close {
  position: absolute;
  right: 0;
  margin-right: 1em;
  cursor: pointer;
}

.examples__code {
  padding-left: 0.5em;
}

.examples__code-comment {
  color: get-color("grey", "dark-2");
}

</style>
