import { render, staticRenderFns } from "./code-syntax-examples.vue?vue&type=template&id=b23ea47a&scoped=true&"
import script from "./code-syntax-examples.vue?vue&type=script&lang=js&"
export * from "./code-syntax-examples.vue?vue&type=script&lang=js&"
import style0 from "./code-syntax-examples.vue?vue&type=style&index=0&id=b23ea47a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b23ea47a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VCol,VIcon})
